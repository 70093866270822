
import logo from "../minicitytransparent.png"
import { NavLink, Outlet } from "react-router-dom";
import React, { useContext } from 'react';
import { AuthContext } from "../AuthProvider";

export default function Menu() {

    const { displayName, logout } = useContext(AuthContext);

    const handleLogout = async () => {
        try {
            await logout(); // Call the logout function from the context
        } catch (error) {
            console.error('Logout failed', error);
        }
    };

    return (
        <>
            <div id="sidebar">
                <nav>
                    <NavLink to="/" className={() => ""}><img src={logo} className="App-logo" alt="logo" /></NavLink>
                    <ul>
                        <li>
                            <NavLink
                                to="/account"
                                className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active"
                                        : isPending
                                            ? "pending"
                                            : ""
                                }>
                                <i className="bi bi-person-circle" />
                                {displayName}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/tempcontrol"
                                className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active"
                                        : isPending
                                            ? "pending"
                                            : ""
                                }>
                                <i className="bi bi-thermometer" />
                                Värmestyrning
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/statistics"
                                className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active"
                                        : isPending
                                            ? "pending"
                                            : ""
                                }>
                                <i className="bi bi-bar-chart-fill" />
                                Statistik
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={handleLogout} className={() => ""}>
                                <i className="bi bi-box-arrow-right" />
                                Logga ut
                            </NavLink>
                        </li>
                    </ul></nav>
            </div>
            <div id="detail">
                <Outlet />
            </div>
        </>
    );
}