import React, { createContext, useState, useEffect } from 'react';
import Parse from 'parse';
import { useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [displayName, setDisplayName] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if a user is already logged in
    const currentUser = Parse.User.current();
    if(currentUser) {
      setUser(currentUser);
      setDisplayName(currentUser.get('name'));
    } else {
      navigate("/login");
    }
    setLoading(false);
  }, [navigate]);

  const login = async (username, password) => {
    try {
      const loggedInUser = await Parse.User.logIn(username, password);
      setUser(loggedInUser);
      return loggedInUser;
    } catch (error) {
      console.error('Login failed', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await Parse.User.logOut();
      setUser(null);
      setDisplayName(null);
    } catch (error) {
      console.error('Logout failed', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, displayName, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
