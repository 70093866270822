import Parse from 'parse';

export const getEspByUser = async (user) => {
    const customerPointer = await user.get("customerPointer");
    if (customerPointer) {
        const query = new Parse.Query('Customer');
        const customer = await query.get(customerPointer.id);
        const espRelation = customer.relation("esps");
        try {
            const query = espRelation.query();
            const esps = await query.find();
            // Handling only one esp by now, change later to handle more
            if (esps.length > 0) {
                return transformEspObjectToEsp(esps[0]);
            } else {
                return [];
            }

        } catch (error) {
            console.error("Error retrieving esps: ", error);
            return [];
        }

    } else {
        console.log("Did not find a customer for user: " + user.get("objectId"));
        return [];
    }
}

export const updateEsp = async (espId, newEspData) => {
    const Esp = Parse.Object.extend('Esp');
    const query = new Parse.Query(Esp);

    try {
        const esp = await query.get(espId);

        esp.set("minTemp", newEspData.minTemp);
        esp.set("idealTemp", newEspData.idealTemp);
        esp.set("maxTemp", newEspData.maxTemp);

        await esp.save();
    } catch (error) {
        console.error('Error updating esp:', error);
    }
};

function transformEspObjectToEsp(dbObject) {
    return {
        id: dbObject.id,
        customerId: dbObject.get("customerId"),
        customerChosenName: dbObject.get("customerChosenName"),
        topicName: dbObject.get("topicName"),
        minTemp: dbObject.get("minTemp"),
        idealTemp: dbObject.get("idealTemp"),
        maxTemp: dbObject.get("maxTemp")
    };
}