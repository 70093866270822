import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from "../AuthProvider";

const Intro = () => {

    const {user} = useContext(AuthContext);
    const [displayName, setDisplayName] = useState(null);

    useEffect(() => {
        if(!user){
            setDisplayName(null);
        } else {
            setDisplayName(user.get('name'));
        }

    }, [user])


    return (
        <p>Välkommen {displayName} till en förträfflig kontrollpanel.</p> 
    )
}

export default Intro;


