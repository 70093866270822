import { NavLink, Outlet } from "react-router-dom";
import React from 'react';
import logo from "../../minicitytransparent.png"

export default function LoginMenu() {

    return (
        <>
            <div id="sidebar">
                <nav>
                    <NavLink to="/"><img src={logo} className="App-logo" alt="logo" /></NavLink>
                    <ul>
                        <li>
                            <NavLink
                                to="/login" end
                                className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active"
                                        : isPending
                                            ? "pending"
                                            : ""
                                }>
                                <i className="bi bi-box-arrow-in-right" />
                                Logga in
                            </NavLink>
                        </li>
                        {/* Måste fixa smtp-server för att dessa två skall fungera korrekt */}
                        {/* <li>
                            <NavLink
                                to="/login/signup"
                                className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active"
                                        : isPending
                                            ? "pending"
                                            : ""
                                }>
                                <i className="bi bi-person-fill-add" />
                                Skapa konto
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/login/requestreset"
                                className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active"
                                        : isPending
                                            ? "pending"
                                            : ""
                                }>
                                <i className="bi bi-question-lg" />
                                Glömt lösenord
                            </NavLink>
                        </li> */}
                    </ul>
                </nav>
            </div>
            <div id="detail">
                <Outlet />
            </div>
        </>
    );
}