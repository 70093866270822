import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Menu from "./routes/menu";
import Login from "./routes/login/login";
import Signup from "./routes/login/signup";
import RequestPasswordReset from "./routes/login/requestpasswordreset";
import ErrorPage from "./error-page";
import Statistics from "./routes/statistics";
import Controls from "./routes/controls";
import Intro from "./routes/intro";
import Account from "./routes/account";
import { Toaster } from "react-hot-toast";
import ResetPassword from "./routes/login/resetpassword";
import LoginMenu from "./routes/login/loginmenu";
import "bootstrap-icons/font/bootstrap-icons.css";
import Tempcontrol from "./routes/tempcontrol";
import { AuthProvider, AuthContext } from "./AuthProvider";

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);

  console.log("Miljövariabler test: " + process.env.REACT_APP_TEST_ENV);

  if (loading) {
    return <div>Loading...</div>;
  } else if (user) {
    return children;
  } else {
    console.log("will go to login page");
    return <Navigate to="/login" />;
  }
};

function App() {
  return (
    <>
      <Toaster />
      <AuthProvider>
        <Routes errorElement={<ErrorPage />}>
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route exact path="/login" element={<LoginMenu />}>
            <Route index="true" element={<Login />} />
            <Route path="/login/signup" element={<Signup />} />
            <Route
              path="/login/requestreset"
              element={<RequestPasswordReset />}
            />
          </Route>
          <Route exact path="/" element={<Menu />}>
            <Route
              index="true"
              element={
                <ProtectedRoute>
                  <Intro />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/controls"
              element={
                <ProtectedRoute>
                  <Controls />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/tempcontrol"
              element={
                <ProtectedRoute>
                  <Tempcontrol />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/statistics"
              element={
                <ProtectedRoute>
                  <Statistics />
                </ProtectedRoute>
              }
            ></Route>
          </Route>
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
