import { Collapse, Spinner } from "react-bootstrap";
import React, { useEffect, useState, useContext } from "react";
import { Form } from "react-bootstrap";
import { getClient, subscribe, publish, unsubscribe } from "../services/mqttClient";
import { AuthContext } from "../AuthProvider";
import { getEspByUser, updateEsp } from "../db/Esp";


const Tempcontrol = () => {
    const [client, setClient] = useState(null);
    const [automationStatus, setAutomationStatus] = useState(null);
    const [isSubed, setIsSub] = useState(false);

    const [topics, setTopics] = useState(null);

    const [tOpen, setTOpen] = useState(true);
    const [esp, setEsp] = useState(null);
    const [espLoading, setEspLoading] = useState(true);

    const {user} = useContext(AuthContext);

    // 1: Set topics with correct esp id from db & get temperatures from db or put default values if not in db
    useEffect(() => {
        async function topics() {
            if (user) {
                const esp = await getEspByUser(user);
                if (esp) {
                    setTopics({ termostatSwitchTopic: "cmnd/" + esp.topicName + "/ThermostatModeSet", sensorTopic: "tele/" + esp.topicName + "/SENSOR" });
                    if (!(esp?.minTemp && esp?.idealTemp && esp?.maxTemp)) {
                        const defaultEsp = { ...esp, minTemp: 19, idealTemp: 20, maxTemp: 22 };
                        updateEsp(defaultEsp.id, defaultEsp);
                        updateMinTemp(defaultEsp.minTemp);
                        updateIdealTemp(defaultEsp.idealTemp);
                        updateMaxTemp(defaultEsp.maxTemp);
                        setEsp(defaultEsp);
                    } else {
                        setEsp(esp);
                    }
                }
                setEspLoading(false);
            }
        }
        topics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // 2: Connect to mqtt server
    useEffect(() => {
        async function connect() {
            if (topics?.sensorTopic) {
                setClient(await getClient());
            }
        }

        connect();

        return () => {
            if (topics?.sensorTopic) {
                unsubscribe({ topic: topics.sensorTopic, qos: 0 });
                setIsSub(false);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topics]);

    // 3: Subscribe to topics and listen for messages
    useEffect(() => {
        if (client && topics) {
            // https://github.com/mqttjs/MQTT.js#event-connect
            client.on("connect", () => {
                console.log("on connect");
                if (!isSubed) {
                    subscribe({ topic: topics.sensorTopic, qos: 0 });
                    setIsSub(true);
                }
            });

            // https://github.com/mqttjs/MQTT.js#event-error
            client.on("error", (err) => {
                console.error("Connection error: ", err);
                client.end();
            });

            // https://github.com/mqttjs/MQTT.js#event-reconnect
            client.on("reconnect", () => {
                console.log("reconnected");
                //setConnectStatus("Reconnecting");
            });

            // https://github.com/mqttjs/MQTT.js#event-message
            client.on("message", (topic, message) => {
                if (topic === topics?.sensorTopic) {
                    const messageObj = JSON.parse(message);
                    const thermostatModeSet = messageObj?.Thermostat1?.ThermostatModeSet;
                    if (thermostatModeSet !== null && thermostatModeSet !== undefined) {
                        setAutomationStatus(thermostatModeSet === 1 ? "ON" : "OFF");
                    }
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, topics]);

    // 4: Skicka kommando för att efterfråga status
    //TODO: även för de 3 temperaturinställningarna
    useEffect(() => {
        if (isSubed && !automationStatus) {
            publish({ topic: topics.termostatSwitchTopic, qos: 0, payload: "" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubed]);

    // Skicka kommando för att slå på/av automation
    const toggleAutomation = () => {
        setAutomationStatus(automationStatus === "ON" ? "OFF" : "ON");
        publish({
            topic: topics.termostatSwitchTopic,
            qos: 0,
            payload: automationStatus === "ON" ? "0" : "1",
        });
    };

    async function updateMinTemp(newValue) {
        publish({ topic: topics?.sensorTopic + "/MIN", qos: 0, payload: newValue + "" });
    }

    async function updateIdealTemp(newValue) {
        publish({ topic: topics?.sensorTopic + "/IDEAL", qos: 0, payload: newValue + "" });
    }

    async function updateMaxTemp(newValue) {
        publish({ topic: topics?.sensorTopic + "/MAX", qos: 0, payload: newValue + "" });
    }

    const handleMinTempInput = (event) => {
        const newValue = parseInt(event.target.value);
        const newEsp = { ...esp };
        newEsp.minTemp = newValue;
        updateMinTemp(newValue);

        if (newEsp.idealTemp < newValue) {
            newEsp.idealTemp = newValue;
            updateIdealTemp(newValue);
        }
        if (newEsp.maxTemp < newValue) {
            newEsp.maxTemp = newValue;
            updateMaxTemp(newValue);
        }
        updateEsp(newEsp.id, newEsp);
        setEsp(newEsp);
    };

    const handleIdealTempInput = (event) => {
        const newValue = parseInt(event.target.value);
        const newEsp = { ...esp };
        newEsp.idealTemp = newValue;
        updateIdealTemp(newValue);

        if (newEsp.minTemp > newValue) {
            newEsp.minTemp = newValue;
            updateMinTemp(newValue);
        }
        if (newEsp.maxTemp < newValue) {
            newEsp.maxTemp = newValue;
            updateMaxTemp(newValue);
        }
        updateEsp(newEsp.id, newEsp);
        setEsp(newEsp);
    };

    const handleMaxTempInput = (event) => {
        const newValue = parseInt(event.target.value);
        const newEsp = { ...esp };
        newEsp.maxTemp = newValue;
        updateMaxTemp(newValue);

        if (newEsp.minTemp > newValue) {
            newEsp.minTemp = newValue;
            updateMinTemp(newValue);
        }
        if (newEsp.idealTemp > newValue) {
            newEsp.idealTemp = newValue;
            updateIdealTemp(newValue);
        }
        updateEsp(newEsp.id, newEsp);
        setEsp(newEsp);
    };

    return (
        <div className="detail-container">
            <div className="card p-3 mb-3">
                <div className="d-flex justify-content-between align-items-center" onClick={() => setTOpen(!tOpen)}>
                    <div className="d-flex align-items-center">
                        {espLoading && (<Spinner animation="border" role="status"></Spinner>)}
                        {!espLoading && !esp && (<h5>Ingen enhet kopplad</h5>)}
                        {esp && (<h5 className="mb-0">{automationStatus === "ON" ? (<i className="bi bi-building-fill-check text-success" />) : automationStatus === "OFF" ? (<i className="bi bi-building-fill-slash text-muted" />) : (<Spinner animation="border" role="status"></Spinner>)}&nbsp;&nbsp;Värmestyrning {esp.customerChosenName}</h5>)} 
                    </div>
                    <i
                        className={tOpen ? "bi bi-caret-up" : "bi bi-caret-down"}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <Collapse in={tOpen}>
                    <div className="mt-3">
                        {!espLoading && !esp && <p>Du har ingen värmestyrningsenhet kopplad till ditt kundkonto.</p>}
                        {/* Värmestyrning av eller på */}
                        {esp && (
                        <div><Form>
                            <Form.Group controlId="formOldPassword">
                                <p>Genom att slå av denna inställning inaktiveras den automatiska styrningen och värmepumpens egen styrning används istället.</p>
                                <Form.Check
                                    type="switch"
                                    id="automation-status-switch"
                                    label="Automatisk värmestyrning"
                                    checked={automationStatus === "ON"}
                                    disabled={!automationStatus}
                                    onChange={toggleAutomation}
                                />
                            </Form.Group>
                        </Form>
                        &nbsp;
                        <Form>
                            <Form.Group controlId="minTempRange">
                                <Form.Label>Minsta temperatur: {esp?.minTemp}°C</Form.Label>
                                <p>Minsta tillåtna komforttemperatur, används vid höga elpriser.</p>
                                <Form.Range
                                    disabled={esp?.minTemp ? false : true}
                                    min={5}
                                    max={30}
                                    value={esp?.minTemp ? esp?.minTemp : 20}
                                    onInput={handleMinTempInput}
                                />
                            </Form.Group>
                            <Form.Group controlId="idealTempRange" className="mt-4">
                                <Form.Label>Idealtemperatur: {esp?.idealTemp}°C</Form.Label>
                                <p>Idealtemperatur, används vid medelhöga elpriser.</p>
                                <Form.Range
                                    disabled={esp?.idealTemp ? false : true}
                                    min={5}
                                    max={30}
                                    value={esp?.idealTemp ? esp?.idealTemp : 20}
                                    onInput={handleIdealTempInput}
                                />
                            </Form.Group>
                            <Form.Group controlId="maxTempRange" className="mt-4">
                                <Form.Label>Högsta temperatur: {esp?.maxTemp}°C</Form.Label>
                                <p>Högsta komforttemperatur, används vid låga elpriser för att buffra värme till dyrare timmar.</p>
                                <Form.Range
                                    disabled={esp?.maxTemp ? false : true}
                                    min={5}
                                    max={30}
                                    value={esp?.maxTemp ? esp?.maxTemp : 20}
                                    onInput={handleMaxTempInput}
                                />
                            </Form.Group>
                        </Form></div>)}

                    </div>
                </Collapse>
            </div>
        </div >
    )
}

export default Tempcontrol;


