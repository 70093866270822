import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  getClient,
  subscribe,
  unsubscribe,
  publish,
} from "../../services/mqttClient";

//const TOPIC_CMND_MATSAL_POWER = "cmnd/lights/matsal/POWER";
//const TOPIC_STAT_MATSAL_POWER = "stat/lights/matsal/POWER";
const TOPIC_TELE_GENIEL_SENSOR = "tele/Genieltest0.01_CECE8C/SENSOR";
const TOPIC_CMD_GENIEL_SENSOR = "cmd/Genieltest0.01_CECE8C/SENSOR";

const HookMqtt = () => {
  const [client, setClient] = useState(null);

  useEffect(() => {
    async function connect() {
      setClient(await getClient());
    }
    connect();

    return () => {
      unsubscribe({ topic: TOPIC_CMD_GENIEL_SENSOR, qos: 0 });
      unsubscribe({ topic: TOPIC_TELE_GENIEL_SENSOR, qos: 0 });
      setIsSub(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isSubed, setIsSub] = useState(false);
  const [tempTargetSet, setTempTargetSet] = useState("Visas snart");
  const [lightStatus, setLightStatus] = useState(null);
  useEffect(() => {
    if (client) {
      // https://github.com/mqttjs/MQTT.js#event-connect
      client.on("connect", () => {
        if (!isSubed) {
          subscribe({ topic: TOPIC_CMD_GENIEL_SENSOR, qos: 0 });
          subscribe({ topic: TOPIC_TELE_GENIEL_SENSOR, qos: 0 });
          setIsSub(true);
        }
      });

      // https://github.com/mqttjs/MQTT.js#event-error
      client.on("error", (err) => {
        console.error("Connection error: ", err);
        client.end();
      });

      // https://github.com/mqttjs/MQTT.js#event-reconnect
      client.on("reconnect", () => {
        console.log("reconnected");
        //setConnectStatus("Reconnecting");
      });

      // https://github.com/mqttjs/MQTT.js#event-message
      client.on("message", (topic, message) => {
        if (topic === TOPIC_TELE_GENIEL_SENSOR) {
          const data = JSON.parse(message);
          const atc = data["Thermostat1"];
          const temp = atc ? atc.tempTargetSet : null;
          if (temp) {
            setTempTargetSet(temp);
          }
        } else if (topic === TOPIC_CMD_GENIEL_SENSOR) {
          setLightStatus(message.toString());
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  useEffect(() => {
    if (isSubed && !lightStatus) {
      // Skicka kommando för att efterfråga status
      publish({ topic: TOPIC_CMD_GENIEL_SENSOR, qos: 0, payload: "" });
    }
    if (isSubed && tempTargetSet === "Visas snart") {
      // Skicka kommando för att efterfråga uppdatering om tempereatur
      publish({ topic: TOPIC_CMD_GENIEL_SENSOR, qos: 0, payload: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubed]);

  // Skicka kommando för att slå på/av lampa
  const toogleLamp = () => {
    publish({
      topic: TOPIC_CMD_GENIEL_SENSOR,
      qos: 0,
      payload: "77",
    });
  };

  return (
    <div>
      <Button id="lampknapp" onClick={toogleLamp} label="Lampknapp">
        {!lightStatus ? "Laddar..." : "ON" === lightStatus ? "Släck" : "Tänd"}
      </Button>
      <p>tempTargetSet: {tempTargetSet}</p>
    </div>
  );
};

export default HookMqtt;
