import React from 'react';
import HookMqtt from "../components/HookMqtt";

const Controls = () => {
    return (
        <HookMqtt></HookMqtt>
    )
}

export default Controls;


